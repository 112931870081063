// Component: UI Loader
// ========================================================================

// ** Base Styles Imports
@import '../../scss/base/bootstrap-extended/include';
@import '../../scss/base/components/include';

.ui-loader {
  position: relative;

  &.show {

    // background-color: rgba($color: $black, $alpha: 0.3);
    .overlay {
      z-index: 9999;
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      background-color: rgba($color: $black, $alpha: 0.3);
    }

    > :first-child,
    code {
      background-color: initial;
    }
  }

  .loading {
    top: 50%;
    left: 50%;
    display: flex;
    position: absolute;
    align-items: center;
    flex-direction: column;
    z-index: 9999;
    justify-content: center;
    transform: translate(-50%, -50%);
    .pencil {
      display: block;
      width: 4em;
      height: 4em;
    }
    .pencil__body1,
    .pencil__body2,
    .pencil__body3,
    .pencil__eraser,
    .pencil__eraser-skew,
    .pencil__point,
    .pencil__rotate,
    .pencil__stroke {
      animation-duration: 3s;
      animation-timing-function: linear;
      animation-iteration-count: infinite;
    }
    .pencil__body1,
    .pencil__body2,
    .pencil__body3 {
      transform: rotate(-90deg);
    }
    .pencil__body1 {
      animation-name: pencilBody1;
    }
    .pencil__body2 {
      animation-name: pencilBody2;
    }
    .pencil__body3 {
      animation-name: pencilBody3;
    }
    .pencil__eraser {
      animation-name: pencilEraser;
      transform: rotate(-90deg) translate(49px,0);
    }
    .pencil__eraser-skew {
      animation-name: pencilEraserSkew;
      animation-timing-function: ease-in-out;
    }
    .pencil__point {
      animation-name: pencilPoint;
      transform: rotate(-90deg) translate(49px,-30px);
    }
    .pencil__rotate {
      animation-name: pencilRotate;
    }
    .pencil__stroke {
      animation-name: pencilStroke;
      transform: translate(100px,100px) rotate(-113deg);
    }
    
    /* Animations */
    @keyframes pencilBody1 {
      from,
      to {
        stroke-dashoffset: 351.86;
        transform: rotate(-90deg);
      }
      50% {
        stroke-dashoffset: 150.8; /* 3/8 of diameter */
        transform: rotate(-225deg);
      }
    }
    @keyframes pencilBody2 {
      from,
      to {
        stroke-dashoffset: 406.84;
        transform: rotate(-90deg);
      }
      50% {
        stroke-dashoffset: 174.36;
        transform: rotate(-225deg);
      }
    }
    @keyframes pencilBody3 {
      from,
      to {
        stroke-dashoffset: 296.88;
        transform: rotate(-90deg);
      }
      50% {
        stroke-dashoffset: 127.23;
        transform: rotate(-225deg);
      }
    }
    @keyframes pencilEraser {
      from,
      to {
        transform: rotate(-45deg) translate(49px,0);
      }
      50% {
        transform: rotate(0deg) translate(49px,0);
      }
    }
    @keyframes pencilEraserSkew {
      from,
      32.5%,
      67.5%,
      to {
        transform: skewX(0);
      }
      35%,
      65% {
        transform: skewX(-4deg);
      }
      37.5%, 
      62.5% {
        transform: skewX(8deg);
      }
      40%,
      45%,
      50%,
      55%,
      60% {
        transform: skewX(-15deg);
      }
      42.5%,
      47.5%,
      52.5%,
      57.5% {
        transform: skewX(15deg);
      }
    }
    @keyframes pencilPoint {
      from,
      to {
        transform: rotate(-90deg) translate(49px,-30px);
      }
      50% {
        transform: rotate(-225deg) translate(49px,-30px);
      }
    }
    @keyframes pencilRotate {
      from {
        transform: translate(100px,100px) rotate(0);
      }
      to {
        transform: translate(100px,100px) rotate(720deg);
      }
    }
    @keyframes pencilStroke {
      from {
        stroke-dashoffset: 439.82;
        transform: translate(100px,100px) rotate(-113deg);
      }
      50% {
        stroke-dashoffset: 164.93;
        transform: translate(100px,100px) rotate(-113deg);
      }
      75%,
      to {
        stroke-dashoffset: 439.82;
        transform: translate(100px,100px) rotate(112deg);
      }
    }}}